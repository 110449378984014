import dayjs from 'dayjs'

export function createByNameSorter({ descending } = { descending: false }) {
  return {
    text: !descending ? 'Name (a - z)' : 'Name (z - a)',
    fieldName: 'Name',
    queryParamKey: descending ? 'name-desc' : 'name-asc',
    icon: !descending ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-alphabetical-descending',
    compareFunc: (a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0) * (descending ? -1 : 1),
  }
}

export function createByPriceSorter({ descending } = { descending: false }) {
  return {
    text: !descending ? 'Price (low to high)' : 'Price (high to low)',
    fieldName: 'Price',
    queryParamKey: descending ? 'price-desc' : 'price-asc',
    icon: !descending ? 'mdi-sort-numeric-ascending' : 'mdi-sort-numeric-descending',
    compareFunc: (a, b) => (a.price < b.price ? -1 : a.price > b.price ? 1 : 0) * (descending ? -1 : 1),
  }
}

export function createByRatingSorter({ descending } = { descending: false }) {
  return {
    text: !descending ? 'Rating (low to high)' : 'Rating (high to low)',
    fieldName: 'Rating',
    queryParamKey: descending ? 'rating-desc' : 'rating-asc',
    icon: !descending ? 'mdi-sort-numeric-ascending' : 'mdi-sort-numeric-descending',
    compareFunc: (a, b) => (a.rating < b.rating ? -1 : a.rating > b.rating ? 1 : 0) * (descending ? -1 : 1),
  }
}

export function createByCreatedDateSorter({ descending } = { descending: false }) {
  return {
    text: !descending ? 'Created Date (oldest to newest)' : 'Created Date (newest to oldest)',
    fieldName: 'Created',
    queryParamKey: descending ? 'created-desc' : 'created-asc',
    icon: !descending ? 'mdi-sort-calendar-ascending' : 'mdi-sort-calendar-descending',
    compareFunc: (a, b) => {
      const createdA = dayjs(a.created)
      const createdB = dayjs(b.created)
      return (createdA < createdB ? -1 : createdA > createdB ? 1 : 0) * (descending ? -1 : 1)
    },
  }
}
