export const hasPriceFilter = {
  text: 'Has price',
  predicate: ({ item }) => item.price && item.price > 0,
}

export const hasRatingFilter = {
  text: 'Has rating',
  predicate: ({ item }) => item.rating && item.rating > 0,
}

export const hasShopFilter = {
  text: 'Has shop',
  predicate: ({ item }) => item.shop && item.shop.length > 0,
}

export const notClaimedByMeFilter = {
  text: 'Not claimed by me',
  predicate: ({ item, authUserId }) => !item.claimedBy || item.claimedBy.claimerId !== authUserId,
}

export const notClaimedByOthersFilter = {
  text: 'Not claimed by others',
  predicate: ({ item, authUserId }) => !item.claimedBy || item.claimedBy.claimerId === authUserId,
}

export const isSurpriseItemFilter = {
  text: 'Surprise Item',
  predicate: ({ item }) => item.isSurprise,
}
