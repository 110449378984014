const urlRegex = /(?:https?|chrome):\/\/[^\s$.?#].[^\s]*/i

/** @param {string} str */
export function extractShopUrlFromString(str) {
  if (!str) return false

  const urls = urlRegex.exec(str)

  if (urls && urls.length > 0) {
    // console.debug('urls', urls)
    const url = urls[0]

    if (url.includes('www.takealot.com')) {
      return {
        shop: 'Takealot',
        icon: 'https://www.takealot.com/static/images/apple-touch-icon.png',
        url,
      }
    }
  }

  return false
}
