<script>
export default {
  computed: {
    scopedSlotKeysExcludingDefault() {
      return Object.keys(this.$scopedSlots).filter((x) => x !== 'default')
    },
  },
}
</script>

<template>
  <v-card v-bind="$attrs" v-on="$listeners" style="position: sticky; z-index: 1" :style="{ top: $vuetify.breakpoint.mdAndUp ? '64px' : '56px' }">
    <template v-for="slot of scopedSlotKeysExcludingDefault" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <slot></slot>
  </v-card>
</template>
