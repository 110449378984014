<script>
import StickyCard from '@/infrastructure/components/StickyCard'

export default {
  components: { StickyCard },

  props: {
    searchText: { type: String, required: true },

    filterOptions: { type: Array, required: true },
    selectedFilterOptions: { type: Array, required: true },

    sortOptions: { type: Array, required: true },
    selectedSortOption: { type: Object },
  },

  data() {
    return {
      isSearchInputFocused: false,
    }
  },

  computed: {
    showSortOptionChip() {
      return this.selectedSortOption && this.selectedSortOption !== this.sortOptions[0]
    },
  },

  methods: {
    onFilterOptionMenuItemClick(option) {
      if (!this.selectedFilterOptions.includes(option)) {
        this.addFilterOption(option)
      } else {
        this.removeFilterOption(option)
      }
    },
    addFilterOption(option) {
      if (this.selectedFilterOptions.includes(option)) return

      const newOptionsArray = this.selectedFilterOptions.map((o) => o)
      newOptionsArray.push(option)
      this.$emit('update:selectedFilterOptions', newOptionsArray)
    },
    removeFilterOption(option) {
      const newOptionsArray = this.selectedFilterOptions.map((o) => o)
      const idx = newOptionsArray.indexOf(option)
      if (idx !== -1) {
        newOptionsArray.splice(idx, 1)
      }
      this.$emit('update:selectedFilterOptions', newOptionsArray)
    },

    addSortOption(option) {
      this.$emit('update:selectedSortOption', option)
    },
    removeSortOption() {
      this.$emit('update:selectedSortOption', this.sortOptions[0])
    },
  },
}
</script>

<template>
  <StickyCard>
    <v-card-text>
      <div class="d-flex align-center">
        <div style="width: 150px">
          <v-text-field
            :value="searchText"
            @input="$emit('update:searchText', $event || '')"
            :placeholder="isSearchInputFocused ? 'Search...' : ''"
            outlined
            hide-details
            dense
            prepend-inner-icon="mdi-magnify"
            :clearable="isSearchInputFocused"
            class="search-field"
            :class="{ 'not-focused': !isSearchInputFocused, 'value-empty': !searchText }"
            @focus="isSearchInputFocused = true"
            @blur="isSearchInputFocused = false"
          />
        </div>

        <v-spacer />

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-sort</v-icon>
            </v-btn>
          </template>
          <v-list subheader>
            <v-subheader>Sort items</v-subheader>
            <v-list-item v-for="option in sortOptions" :key="option.text" @click="addSortOption(option)">
              <v-list-item-title :class="{ 'font-weight-bold': selectedSortOption === option }">
                {{ option.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
          </template>
          <v-list subheader>
            <v-subheader>Filter items</v-subheader>
            <v-list-item v-for="option in filterOptions" :key="option.text" @click="onFilterOptionMenuItemClick(option)">
              <v-list-item-title :class="{ 'font-weight-bold': selectedFilterOptions.includes(option) }">{{ option.text }} </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <slot name="extra-buttons"></slot>
      </div>

      <v-expand-transition>
        <div v-if="(selectedFilterOptions && selectedFilterOptions.length > 0) || showSortOptionChip">
          <div class="mt-3"></div>

          <v-chip-group column>
            <v-chip v-if="showSortOptionChip" color="blue-grey" dark outlined close @click:close="removeSortOption(selectedSortOption)">
              <v-icon size="20" class="mr-1">{{ selectedSortOption.icon }}</v-icon>
              {{ selectedSortOption.fieldName }}
            </v-chip>

            <v-chip v-for="option in selectedFilterOptions" :key="option.text" color="primary" close @click:close="removeFilterOption(option)">
              {{ option.text }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-expand-transition>
    </v-card-text>
  </StickyCard>
</template>

<style scoped>
.search-field.not-focused.value-empty >>> fieldset {
  /*border-color: transparent;*/
}
</style>
