<script>
import { extractShopUrlFromString } from '@/features/shops/services/shopSearchService'
import store from '@/infrastructure/store'

export default {
  props: {
    phrases: { type: Array, required: true },
  },

  data() {
    return {
      shopPrompt: false,
      matchedShopUrlDetails: false,
      isLoading: false,
    }
  },

  watch: {
    phrases(to) {
      if (!to) return

      const firstMatchingShopUrl = to.map((phrase) => extractShopUrlFromString(phrase)).find((url) => !!url)
      if (firstMatchingShopUrl && (!this.matchedShopUrlDetails || firstMatchingShopUrl.url !== this.matchedShopUrlDetails.url)) {
        this.matchedShopUrlDetails = firstMatchingShopUrl
        this.shopPrompt = true
      }
    },
  },

  methods: {
    async autoDetectFromShop() {
      this.isLoading = true
      try {
        const itemDetails = await store.dispatch('items/autoDetectItemDetailsFromShopUrl', { shopUrl: this.matchedShopUrlDetails.url })
        this.$emit('found-item-details', itemDetails)
      } finally {
        this.shopPrompt = false
        this.isLoading = false
      }
    },
  },
}
</script>

<template>
  <div>
    <v-snackbar app :timeout="-1" v-model="shopPrompt" vertical centered>
      <div class="d-flex align-center mb-1">
        <div v-if="matchedShopUrlDetails.icon" class="mr-2">
          <v-img :src="matchedShopUrlDetails.icon" width="40" />
        </div>
        <div>
          <div>{{ matchedShopUrlDetails.shop }} product detected</div>
          <div>Do you want to auto detect the item information?</div>
        </div>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" :disabled="isLoading" @click="shopPrompt = false"> No</v-btn>
        <div class="pl-5"></div>
        <v-btn v-bind="attrs" color="primary" :loading="isLoading" :disabled="isLoading" @click="autoDetectFromShop"> Yes </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
