<script>
import ItemRating from '@/features/items/components/ItemRating'
import store from '@/infrastructure/store'
import LinksManager from '@/features/items/components/LinksManager'
import ShopProductDetailsAutoFindPrompt from '@/features/items/components/ShopProductDetailsAutoFindPrompt'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import { onMounted } from '@vue/composition-api'
import ItemImageEditor from '@/features/images/components/ItemImageEditor'
import useApiImageUploading from '@/features/images/composables/imageFileComposables'
import config from '@/config'

export default {
  components: {
    ItemRating,
    LinksManager,
    ShopProductDetailsAutoFindPrompt,
    ItemImageEditor,
  },

  props: {
    friendUserId: { type: String },
    friendUserName: { type: String },

    initialPrice: { type: Number, default: () => 0 },

    imageWidth: { type: Number, default: () => 120 },
  },

  setup(props) {
    const { isBusy: isLoadingUpcomingSecretSantaEvents, execute: loadUpcomingSecretSantaEvents } = useAsyncLoading({
      actionFactory: () => {
        if (!props.friendUserId) return null
        return store.dispatch('items/fetchReasonsToAddFriendItemInSecretInstead', { friendUserId: props.friendUserId })
      },
    })

    const {
      isBusyUploading: isBusyUploadingImage,
      progressPercent: imageUploadPercentage,
      error: imageUploadError,
      executeUpload: executeImageUpload,
    } = useApiImageUploading()

    onMounted(loadUpcomingSecretSantaEvents)

    return {
      isLoadingUpcomingSecretSantaEvents,

      isBusyUploadingImage,
      imageUploadPercentage,
      imageUploadError,
      executeImageUpload,
    }
  },

  data() {
    if (this.friendUserId && !this.friendUserName) {
      alert('friendUserName is required when friendUserId is specified')
      return {}
    }

    return {
      name: '',
      rating: 0,
      price: this.initialPrice,
      shop: '',
      description: '',
      links: [],
      mainImageId: undefined,

      rules: {
        nameRequired: (value) => !!value || 'Name is required',
      },
      formValid: false,
      busySaving: false,

      inputsEnabled: true,

      refreshLinksManager: 0,
    }
  },

  computed: {
    thumbnailFullUrl() {
      if (!this.mainImageId) return undefined
      return `${config.ApiBaseUrl}/ImageFiles/images/${this.mainImageId}/thumbnail/${this.imageWidth}/${this.imageWidth}`
    },
    phrasesToWatchForShopUrls() {
      return [this.description, ...this.links]
    },
    reasonsToAddFriendItemInSecretInstead() {
      return store.state.items.reasonsToAddFriendItemInSecretInsteadByUserId[this.friendUserId]
    },
    hasReasonsToAddFriendItemInSecretInstead() {
      return !!(this.reasonsToAddFriendItemInSecretInstead && this.reasonsToAddFriendItemInSecretInstead.length > 0)
    },
  },

  methods: {
    async addItem({ addSecretly } = {}) {
      this.busySaving = true
      try {
        const newItem = {
          name: this.name,
          rating: this.rating,
          price: this.price,
          shop: this.shop,
          description: this.description,
          links: this.links,
          mainImageId: this.mainImageId,
        }

        if (this.friendUserId) {
          await store.dispatch('items/addFriendItem', {
            userId: this.friendUserId,
            item: newItem,
            addSecretly: addSecretly,
          })
        } else {
          await store.dispatch('items/addMyItem', { item: newItem })
        }

        this.$emit('added')

        this.clearForm()
      } finally {
        this.busySaving = false
      }
    },

    addItemSecretly() {
      this.addItem({ addSecretly: true })
    },

    clearForm() {
      this.name = ''
      this.rating = 0
      this.price = this.initialPrice
      this.shop = ''
      this.description = ''
      this.links = []
      this.mainImageId = undefined

      this.$refs.form.reset()
    },

    onNameFieldBlur() {
      if (!this.name) {
        this.$nextTick(() => this.clearForm())
      }
    },

    autoFillDetailsFromShopProduct(itemDetails) {
      if (itemDetails.name) this.name = itemDetails.name
      if (itemDetails.shop) this.shop = itemDetails.shop
      if (itemDetails.price) this.price = Number(itemDetails.price)
      if (itemDetails.url) this.links = [itemDetails.url]
      // if (itemDetails.imageUrl) this.mainImageId = itemDetails.imageUrl  //TODO: upload first to get ImageId from API

      this.refreshLinksManager++

      store.commit('addToast', { message: 'Item details successfully filled in', color: 'success' })
    },

    onReplaceItemImage({ imageId }) {
      this.mainImageId = imageId
    },
    onRemoveItemImage() {
      this.mainImageId = undefined
    },
  },
}
</script>

<template>
  <v-card :loading="busySaving">
    <v-form ref="form" v-model="formValid" autocomplete="off">
      <v-card-text class="pb-0">
        <v-text-field
          label="New item name"
          v-model="name"
          :disabled="!inputsEnabled"
          :rules="[rules.nameRequired]"
          filled
          clearable
          @click:clear="clearForm"
          @blur="onNameFieldBlur"
        />
      </v-card-text>

      <v-expand-transition>
        <div v-if="name">
          <v-card-text class="pt-0">
            <ItemRating v-model="rating" :readonly="!inputsEnabled" />

            <div class="mt-3"></div>

            <v-text-field label="Price" v-model.number="price" hide-details :disabled="!inputsEnabled" type="number" filled />

            <div class="mt-5"></div>

            <v-text-field label="Shop name" v-model="shop" hide-details :disabled="!inputsEnabled" filled />

            <div class="mt-5"></div>

            <v-textarea label="Description" v-model="description" hide-details :disabled="!inputsEnabled" filled />

            <div class="mt-5"></div>

            <LinksManager :key="refreshLinksManager" v-model="links" :disabled="!inputsEnabled" />

            <div class="pt-3"></div>

            <ItemImageEditor
              :image-width="120"
              :image-upload-percentage="imageUploadPercentage"
              :upload-error="imageUploadError"
              :execute-upload="executeImageUpload"
              :replace-item-image="onReplaceItemImage"
              :remove-item-image="onRemoveItemImage"
              :thumbnail-full-url="thumbnailFullUrl"
            />

            <div class="pt-5"></div>

            <v-divider />

            <div class="pt-5"></div>

            <div v-if="hasReasonsToAddFriendItemInSecretInstead">
              <v-alert type="warning" dark outlined :value="true">
                <div v-for="reason in reasonsToAddFriendItemInSecretInstead" :key="reason">{{ reason }}</div>
              </v-alert>

              <div class="d-flex align-center">
                <v-spacer />
                <v-btn text :disabled="!formValid || busySaving || isBusyUploadingImage" :loading="busySaving" class="mr-3" @click="addItem">
                  Add normal
                </v-btn>
                <v-btn :disabled="!formValid || busySaving || isBusyUploadingImage" :loading="busySaving" color="primary" @click="addItemSecretly">
                  Add secretly
                </v-btn>
              </div>
            </div>

            <div v-else class="d-flex align-center">
              <v-btn text @click="clearForm"> Cancel</v-btn>
              <v-spacer />
              <v-btn color="primary" :disabled="!formValid || busySaving || isBusyUploadingImage" :loading="busySaving" @click="addItem"> Add item </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-form>

    <ShopProductDetailsAutoFindPrompt :phrases="phrasesToWatchForShopUrls" @found-item-details="autoFillDetailsFromShopProduct" />
  </v-card>
</template>
